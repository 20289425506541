import { Col, Row } from "antd";
import React from "react";
import { motion } from "framer-motion";
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer";
import PanoramaImage from "../../images/panorama.jpg";
import "./Panorama.css";

const Panorama = () => {
    return (
        <motion.div className="zoom" initial={{ opacity: 0 }} whileInView={{ opacity: 1 }} viewport={{ once: true }} transition={{ ease: "easeOut", duration: 1 }}>
            <section>
                <div className="inner">
                    <Row gutter={[14, 14]}>
                        <Col span={24}>
                            <h2>Панорама</h2>
                        </Col>
                        <Col span={24}>
                            <ReactPhotoSphereViewer src={PanoramaImage} height={600} width={"100%"} />
                        </Col>
                    </Row>
                </div>
            </section>
        </motion.div>
    );
};

export default Panorama;
