const checkPrefix = (phoneNumber) => {
    const prefix = phoneNumber.match(/\((\d{3})\)/);

    if (prefix && prefix[1]) {
        const prefixList = [
            "700",
            "701",
            "702",
            "703",
            "704",
            "705",
            "706",
            "707",
            "708",
            "709",
            "747",
            "750",
            "751",
            "760",
            "761",
            "762",
            "763",
            "764",
            "771",
            "775",
            "776",
            "777",
            "778",
        ];
        return prefixList.includes(prefix[1]);
    }

    return false;
};

export default checkPrefix;
